import React, {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import Auth from "../auth";
import {Alert, AlertTitle, Button, Paper, Stack, TextField, Typography,} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {PulseLoader} from "react-spinners";
import {useResponsive} from "../hooks/style";

type ConfirmPasswordResetProps = {
  username: string;
};

const initialMessage =
  'Please check your email for a 6-digit confirmation code.  If you don\'t see a message, please check your spam folder or "All Mail" folder';

const ConfirmPasswordReset: FC<ConfirmPasswordResetProps> = ({ username }) => {
  const navigate = useNavigate();
  const [actionSuccess, setActionSuccess] = useState<string>("");
  const [actionError, setActionError] = useState<any | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      confirmCode: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const {isDesktop, isPhone} = useResponsive();

  const handleActionSuccess = (successMessage) => {
    setActionSuccess(successMessage);
    setActionError(null);
  };

  const handleActionError = (error) => {
    setActionSuccess("");
    setActionError(error);
  };

  const onConfirmSubmit = (values) => {
    setLoading(true);
    if (values.newPassword !== values.confirmNewPassword) {
      handleActionError("New Password and Confirm Password do not match");
    }
    Auth.updateForgottenPassword(
      username,
      values.confirmCode,
      values.newPassword
    )
      .then(() => {
        setLoading(false);
        handleActionSuccess(
          "Confirmation Successful!  Redirecting to login..."
        );
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        handleActionError(error.message);
      });
  };

  const onConfirmResend = () => {
    Auth.forgotPassword(username)
      .then(() => {
        handleActionSuccess(
          `New confirmation code successfully sent.  Please check the email associated with ${username}`
        );
      })
      .catch((error) => {
        handleActionError(error.message);
      });
  };

  const performSubmit = (values) => {
    onConfirmSubmit(values);
  };

  const performResend = (event) => {
    onConfirmResend();
  };

  return (
    <Paper sx={{ px: isPhone ? 2 : 0, py: 6, maxWidth: "sm", mx: isPhone ? 2 : "auto" }}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ maxWidth: 300, mx: "auto" }}
      >
        {!!actionError && (
          <Alert severity="error">
            <AlertTitle>Confirmation Failed</AlertTitle>
            {actionError.message}
          </Alert>
        )}
        {!!actionSuccess && (
          <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>
            {actionSuccess}
          </Alert>
        )}
        {!actionError && !actionSuccess && (
          <Alert severity="info">
            <AlertTitle>Confirmation Code Sent</AlertTitle>
            <Typography variant="body2">{initialMessage}</Typography>
          </Alert>
        )}

        <form onSubmit={handleSubmit(performSubmit)}>
          <Controller
            control={control}
            name="confirmCode"
            rules={{ required: "Confirmation code required" }}
            render={({ field, fieldState }) => (
              <TextField
                error={!!fieldState.error}
                fullWidth
                helperText={fieldState.error?.message}
                label="Confirm Code"
                value={field.value}

                onChange={field.onChange}
                onBlur={field.onBlur}
                sx={{ my: 1 }}
              />
            )}
          />
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: "Must confirm password",
              pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.[{}();.#@$^&!%*?&'"\]\[])[A-Za-z\d{}();.#@$^&!%*?&'"\]\[]{10,}$/, message: 'Minimum of 10 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character.'}
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  error={!!fieldState.error}
                  fullWidth
                  label="New Password"
                  type="password"
                  helperText={fieldState.error?.message || "Minimum of 10 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character."}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  sx={{ my: 1 }}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="confirmNewPassword"
            rules={{
              required: "Must confirm password",
              validate: (value) =>
                value === getValues("newPassword") ||
                "Password and confirm password do not match",
            }}
            render={({ field, fieldState }) => (
              <TextField
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                label="Confirm New Password"
                type="password"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                sx={{ my: 1 }}
              />
            )}
          />
          <Stack direction="row" justifyContent="flex-end" width="100%" mt={1}>
            <Button onClick={performResend}>Request New Code</Button>
            <Button color="primary" type="submit" variant="contained" disabled={isLoading}>
              { isLoading ? (
                <PulseLoader color='white'/>
              ) : 'Confirm' }
            </Button>
          </Stack>
        </form>
      </Stack>
    </Paper>
  );
};

export default ConfirmPasswordReset;
