import {useContext, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl, InputLabel, MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {LoadingButton} from '@mui/lab';
import {PictureAsPdf} from '@mui/icons-material';
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";

import TabPanel from "./TabPanel";
import AgreementsPanel from "./AgreementsPanel";
import InformationPanel from "./InformationPanel";
import CharacteristicsPanel from "./CharacteristicsPanel";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import herdAPI from "../../apis/herdAPI";
import {MessageContext} from "../../context/messageContext";
import {exportToPdfImage} from "../../util/pdf";
import PdfWrapper from "../../components/PdfWrapper";
import {useResponsive} from "../../hooks/style";
import HistoryPanel from "./HistoryPanel";

const ViewHerd = () => {
  const {isDesktop, isPhone} = useResponsive();

  const tabList = ["General Information", "Characteristics", "Agreements", "History"];

  const { id: herdCode } = useParams();
  const { palette, typography } = useTheme();
  const {
    messageState: [, setMessage],
  } = useContext(MessageContext);

  const [selectedTab, setSelectedTab] = useState(0);

  /**
   * QUERY FOR SINGLE HERD
   */
  const foundHerd = useQuery(
    ["getOneHerd", herdCode],
    () => herdAPI.getOne(herdCode ? herdCode : ""),
    {
      onError: (error) => {
        console.error("getOneHerd useQuery Error:", error);
        setMessage({ type: "error", message: error.message });
      },
    }
  );

  const exportPdf = useQuery(
    ['exportPdf'],
    () => exportToPdfImage(['information', 'characteristics', 'agreements'], true),
    {
      enabled: false
    }
  );

  const handleTabChange = (event, newValue) => {
    if (isPhone) {
      setSelectedTab(event.target.value);
    }
    else {
      setSelectedTab(newValue);
    }
  };

  const handleQueryRefetch = () => {
    foundHerd.refetch();
  };

  return (
    <>
      <BreadcrumbNav tab={tabList[selectedTab]}/>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{
          backgroundColor: palette.background.paper,
          alignItems: 'center',
          pt: isPhone ? 1 : 0,
          px: {xs: 1, md: 0}
        }}
      >
        {
          isPhone ?
            <FormControl fullWidth sx={{pr: 2}}>
              <InputLabel>Tab</InputLabel>
              <Select
                id='mobile-tab-select'
                size='small'
                label='Tab'
                value={selectedTab}
                onChange={handleTabChange}
                sx={{color: palette.primary.main}}
              >
                {tabList.map((tab, index) => (
                  <MenuItem value={index} key={tab}>{tab}</MenuItem>
                ))}
              </Select>
            </FormControl> :
            <Tabs
              value={selectedTab}
              variant="scrollable"
              scrollButtons="auto"
              // allowScrollButtonsMobile
              onChange={handleTabChange}
              aria-label="Herd section tabs."
            >
              {tabList.map((tab) => (
                <Tab
                  key={tab}
                  label={tab}
                  sx={{
                    px: { xs: 0.5, sm: 1, md: 2 },
                  }}
                />
              ))}
            </Tabs>
        }

        <LoadingButton
          size='small'
          disabled={!foundHerd.isSuccess}
          onClick={() => exportPdf.refetch()}
          endIcon={<PictureAsPdf/>}
          loading={exportPdf.isFetching}
          loadingPosition='end'
          variant='outlined'
          sx={{flexShrink: 0}}
        >
          Export
        </LoadingButton>
      </Stack>

      {foundHerd.isFetched && !foundHerd.isLoading ? (
        <>
          <TabPanel value={selectedTab} index={0}>
            <InformationPanel
              id='information-display'
              herd={foundHerd.data}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <CharacteristicsPanel
              id='characteristics-display'
              herd={foundHerd.data}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <AgreementsPanel
              id='agreements-display'
              herd={foundHerd.data}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <HistoryPanel
              id='history-display'
              herd={foundHerd.data}
              herdRefetch={handleQueryRefetch}
            />
          </TabPanel>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="info" />
        </Box>
      )}

      {foundHerd.isSuccess &&
        <PdfWrapper id='pdf-export'>
          <InformationPanel
            id='information'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
            showButton={false}
            usePdfLayout
          />
          <CharacteristicsPanel
            id='characteristics'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
            showButton={false}
            usePdfLayout
          />
          <AgreementsPanel
            id='agreements'
            herd={foundHerd.data}
            herdRefetch={handleQueryRefetch}
            showButton={false}
            usePdfLayout
          />
        </PdfWrapper>
      }
    </>
  );
};

export default ViewHerd;
