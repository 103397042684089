import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Mail, Check, Cancel } from "@mui/icons-material";

import { suColumns } from "./suColumns";
import { adminColumns } from "./adminColumns";
import CreateUserDialog from "./CreateUserDialog";
import personAPI from "../apis/personAPI";
import useSession from "../hooks/user/useSession";
import userAPI from "../apis/userAPI";
import useAlert from "../hooks/alert/useAlert";

const UserViewPage = () => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { userInfo } = useSession();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleApiError = (err, message) => {
    if (err.response) {
      alert.error(`${message}: ${err.response.data.message}`);
    } else {
      alert.error(`${message}: ${err.message}`);
    }
  };

  const getUsersForOrganization = useQuery(
    ["usersForOrg", userInfo.orgId],
    personAPI.get,
    {
      enabled: userInfo.orgId !== 0,
      select: (response) => response.data,
    }
  );

  const deactivateUser = useMutation((userId) => userAPI.deactivate(userId), {
    onSuccess: () => {
      alert.success("Successfully deactivated user");
      queryClient.invalidateQueries(["usersForOrg", userInfo.orgId]);
    },
    onError: (err) => handleApiError("Could not deactivate user", err),
    retry: 1,
  });

  const activateUser = useMutation((userId) => userAPI.activate(userId), {
    onSuccess: () => {
      alert.success("Successfully re-activated user");
      queryClient.invalidateQueries(["usersForOrg", userInfo.orgId]);
    },
    onError: (err) => handleApiError("Could not re-activate user", err),
    retry: 1,
  });

  const resetPassword = useMutation((userId) => userAPI.resetPassword(userId), {
    onSuccess: () => {
      alert.success("Re-sent temporary password to user");
    },
    onError: (err) => handleApiError("Could not reset password", err),
    retry: 1,
  });

  const somethingIsLoading =
    getUsersForOrganization.isLoading ||
    deactivateUser.isLoading ||
    activateUser.isLoading ||
    resetPassword.isLoading;

  const handleCreateSuccess = () => {
    setCreateDialogOpen(false);
    alert.success("Successfully created user accounts");
    queryClient.invalidateQueries(["usersForOrg", userInfo.orgId]);
  };

  const handleCreateUsersError = (msg) => {
    setCreateDialogOpen(false);
    alert.error(msg);
  };

  const columns = (
    userInfo.orgTypeAbbrev === "SU" ? suColumns : adminColumns
  ).concat({
    field: "actions",
    headerName: "Actions",
    headerClassName: "header",
    minWidth: 140,
    renderCell: (params) => (
      <Box
        key={params.row.user_id}
        sx={{ width: "100%", display: "flex", gap: 1 }}
      >
        {params.row.active ? (
          <Tooltip title="Deactivate user">
            <IconButton
              onClick={() => deactivateUser.mutate(params.row.user_id)}
            >
              <Check />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Activate user">
            <IconButton onClick={() => activateUser.mutate(params.row.user_id)}>
              <Cancel />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Reset Password">
          <IconButton onClick={() => resetPassword.mutate(params.row.user_id)}>
            <Mail />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return (
    <Box sx={{px: {xs: 1, md: 0}}}>
      <CreateUserDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={handleCreateSuccess}
        onError={handleCreateUsersError}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        my="1rem"
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => setCreateDialogOpen(true)}
        >
          Create
        </Button>
      </Stack>
      <Box sx={{ height: "65vh" }}>
        <DataGridPro
          sx={(theme) => ({
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.secondary.main,
            },
          })}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          columns={columns}
          getRowId={(row) => row.user_id}
          pinnedColumns={{ left: ["actions"] }}
          rows={getUsersForOrganization.data || []}
          loading={somethingIsLoading}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default UserViewPage;
