import { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import {
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Alert,
  Typography, styled
} from '@mui/material';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import { useQuery } from 'react-query';
import { InformationPanelFormState } from './InformationPanel';
import { HerdViewObject, ValidationFn } from '../../herdportalTypes';
import orgAPI from '../../apis/orgAPI';
import {useResponsive} from "../../hooks/style";

interface HerdInfoFormProps {
  herd: HerdViewObject;
  formState: InformationPanelFormState;
  setFormState: Dispatch<InformationPanelFormState>;
  validationFn: ValidationFn;
  usePdfLayout?: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#01674F',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function HerdInformationForm({ herd, formState, setFormState, validationFn, usePdfLayout = false }: HerdInfoFormProps) {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const speciesOptions = ['Cow', 'Goat']; // DO NOT CHANGE THIS IS THE ONLY HARD REFERENCE TO INT
  const foundOrgs = useQuery('allOrgs', () => orgAPI.getAll());

  const [FSPOptions, setFSPOptions] = useState<Record<string, string>[]>([]);
  const [DRPCOptions, setDRPCOptions] = useState<Record<string, string>[]>([]);

  const {isDesktop, isPhone} = useResponsive();

  const renderAlerts = () => {
    return errorMessages.map((msg, idx) => (
      <Alert key={idx} severity='error'>{msg}</Alert>
    ));
  };

  const maskCode = (codeNumb) => {
    const code = codeNumb.toString();
    if (code.length === 8) {
      return code.substring(0, 2) + '-' + code.substring(2, 4) + '-' + code.substring(4, 8)
    }
    return code;
  };

  const addError = (errorMessages, errorMessage) => {
    if (errorMessage) errorMessages.push(errorMessage);
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = event;
    const currentValue = formState[name];
    setFormState({ ...formState, [name]: !currentValue });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = event;
    const parseList = ['code', 'num_cows'];
    const errMessages = [];
    switch (name) {
      case 'num_cows':
        addError(errMessages, validationFn.validateCount(value));
        setErrorMessages(errMessages);
        break;
    }
    setFormState({ ...formState, [name]: parseList.includes(name) && value !== '' ? parseInt(value) : value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { target: { name, value } } = event;
    setFormState({ ...formState, [name]: value });
  }

  const handleDateChange = (value: string) => {
    setFormState({ ...formState, person_providing_info_datetime: value });
  }

  useEffect(() => {
    if (foundOrgs.isSuccess) {
      const allOrgs = foundOrgs.data as Record<string, string>[]
      setFSPOptions(allOrgs.filter((orgObj) => orgObj.org_type_abbrev === 'FSP'));
      setDRPCOptions(allOrgs.filter((orgObj) => orgObj.org_type_abbrev === 'DRPC'));
    }
  }, [foundOrgs.isSuccess])
  return (
    <Stack direction="column" spacing={2} sx={{ width: '100%', maxWidth: 'sm' }}>
      {renderAlerts()}
      <Stack direction="row" sx={{justifyContent: 'space-between'}}>
        <TextField
            required
            id="herd-code-input"
            name="code"
            label="Code"
            value={maskCode(formState.code)}
            sx={{ width: 150, pr: 2 }}
            disabled={true}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Inactive</Typography>
          <AntSwitch
              checked={formState.is_active}
              onChange={handleSwitchChange}
              inputProps={{ 'aria-label': 'ant design' }}
              name="is_active"
          />
          <Typography>Active</Typography>
        </Stack>
      </Stack>

      <TextField
        id="herd-owner-input"
        name="owner_name"
        label="Owner Name"
        value={formState.owner_name}
        placeholder='John Jacob Jingleheimer Schmidt'
        onChange={handleInputChange}
        fullWidth
      />

      <TextField
        id="herd-farm-name-input"
        name="farm_name"
        label="Farm Name"
        value={formState.farm_name}
        onChange={handleInputChange}
        fullWidth
      />

      <Stack direction="row" spacing={2}>
        <FormControl sx={{ width: 150 }}>
          <InputLabel id="herd-species-select-label">Species</InputLabel>
          <Select
            labelId="herd-species-select-label"
            id="herd-species-select"
            name="species_code"
            defaultValue='Bovine'
            value={`${formState.species_code === null ? '' : formState.species_code}`}
            label="Species"
            onChange={handleSelectChange}
          >
            {speciesOptions?.map((option, idx) => (
              <MenuItem key={option} value={idx}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="herd-animal-count-input"
          name="num_cows"
          label="Animal Count"
          value={formState.num_cows}
          type="number"
          onChange={handleInputChange}
          sx={{ width: 150 }}
        />
      </Stack>

      <Stack direction={usePdfLayout ? 'column' : {xs: 'column', sm: 'row'}} spacing={2}>
        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <InputLabel id="herd-FSP-select-label">FSP</InputLabel>
          <Select
            labelId="herd-FSP-select-label"
            id="herd-FSP-select"
            name="fsp_id"
            value={`${formState.fsp_id ?? ''}`}
            label="FSP"
            onChange={handleSelectChange}
            autoWidth={true}
            MenuProps={{slotProps: {paper: {sx: {maxWidth: isPhone ? '60vw' : 'none'}}}}}
          >
            <MenuItem value="">No FSP Selected</MenuItem>
            {FSPOptions?.map((option) => (
              <MenuItem key={option.code} value={option.code}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <InputLabel id="herd-DRPC-select-label">DRPC</InputLabel>
          <Select
            labelId="herd-DRPC-select-label"
            id="herd-DRPC-select"
            name="drpc_id"
            value={`${formState?.drpc_id || ''}`}
            label="DRPC"
            onChange={handleSelectChange}
            autoWidth={true}
            MenuProps={{slotProps: {paper: {sx: {maxWidth: isPhone ? '60vw' : 'none'}}}}}
          >
            <MenuItem value="">No DRPC Selected</MenuItem>
            {DRPCOptions?.map((option) => (
              <MenuItem key={option.code} value={option.code}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction={usePdfLayout ? 'column' : {xs: 'column', sm: 'row'}} spacing={2}>
        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <TextField
              id="person-providing-info-input"
              name="person_providing_info"
              label="Person Providing Info"
              value={formState.person_providing_info || ''}
              onChange={handleInputChange}
          />
        </FormControl>
        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <TextField
              id="person-providing-info-input-datetime"
              name="person_providing_info_datetime"
              label="Person Providing Info Date/Time"
              value={formState.person_providing_info_datetime || ''}
              onChange={handleInputChange}
          />
        </FormControl>
      </Stack>

      <Stack direction={usePdfLayout ? 'column' : {xs: 'column', sm: 'row'}} spacing={2}>
        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <TextField
              id="person-providing-info-from-producer-input"
              name="person_providing_info_from_producer"
              label="Person Providing Info/Producer"
              value={formState.person_providing_info_from_producer || ''}
              inputProps={ { readOnly: true, } }
          />
        </FormControl>
        <FormControl sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '50%'}}}>
          <TextField
              id="person-providing-info-from-producer-input-datetime"
              name="person_providing_info_from_producer_datetime"
              label="Person Providing Info/Producer Date/Time"
              value={formState.person_providing_info_from_producer_datetime || ''}
              inputProps={ { readOnly: true, } }
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}