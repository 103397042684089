import {useState, useContext, useEffect, useMemo} from 'react';
import { AxiosError } from 'axios';
import {useMutation, UseMutationResult, useQuery, useQueryClient} from 'react-query';
import { Stack, Button, CircularProgress, Box } from '@mui/material';
import {
  HerdInfoObject,
  HerdLocationObject,
  HerdOrgsObject,
  HerdPanelProps
} from '../../herdportalTypes';
import { MessageContext } from '../../context/messageContext';
import HerdInformationForm from './HerdInformationForm';
import HerdLocationForm from './HerdLocationForm';
import herdAPI, {HerdRequestType} from '../../apis/herdAPI';
import orgAPI from "../../apis/orgAPI";
import UsernameDateTime from './UserDateTimeBox';
import { jsonDiff } from '../../util';

export interface InformationPanelFormState extends HerdInfoObject, HerdLocationObject, HerdOrgsObject { }
const checkBool = (val) => {
  if (val === false || val === 0) {
    return false;
  } else if ( val === true || val === 1) {
    return true
  }
  return true;
};

const hasValidationError = (re, val) => {
  const result = re.exec(val);
  return !result;
};

const validateFn = {
  validateCity: (val) => {
    const re = /^([a-zA-Z0-9 -]+)$/;
    const message = `City value="${val}", can only be alpha, numeric, spaces, or dashes.`;

    if (hasValidationError(re, val)) {
      return message;
    }
  },
  validateZip: (val) => {
    const re = /^([0-9-]+)$/;
    const message = `Zipcode value="${val}", can only be numeric, or dashes.`;

    if (hasValidationError(re, val)) {
      return message;
    }

    if (val.length < 5) {
      return `Zipcode value="${val}, must be a least 5 digits.`;
    }
  },
  validateCount: (val) => {
    const re = /^([0-9]+)$/;
    const message = `Animal Count value="${val}", can only be numeric.`;

    if (hasValidationError(re, val)) {
      return message;
    }
  }
}

export default function InformationPanel({ id, herd, herdRefetch, showButton = true, usePdfLayout = false }: HerdPanelProps) {
  const queryClient = useQueryClient();

  if (Array.isArray(herd)) {
    herd = herd[0];
  }

  const orgQuery = useQuery('allOrgs', () => orgAPI.getAll());
  const allOrgs = orgQuery.data as Record<string, string>[];
  const parsedInfoFormState = useMemo(() => ({
    code: herd.code || 0,
    farm_name: herd.farm_name || '',
    owner_name: herd.owner_name || '',
    species_code: herd.species_code || 0,
    num_cows: herd.num_cows || 0,
    street_address: herd.street_address || '',
    city: herd.city || '',
    state_code: herd.state_code || '',
    zip_code: herd.zip_code || '',
    drpc_id: herd.drpc_code || 0,
    fsp_id: herd.fsp_code || 0,
    herd_updated_datetime: herd.herd_updated_datetime || null,
    herd_updated_by: herd.herd_updated_by || '',
    herd_updated_by_organization: herd.herd_updated_by_organization || '',
    person_providing_info: herd.person_providing_info || '',
    person_providing_info_datetime: herd.person_providing_info_datetime || null,
    person_providing_info_from_producer: herd.person_providing_info_from_producer || '',
    person_providing_info_from_producer_datetime: herd.person_providing_info_from_producer_datetime || null,
    is_active: checkBool(herd.is_active)
  }), [herd]);

  const { messageState: [, setMessage] } = useContext(MessageContext);

  const [infoFormState, setInfoFormState] = useState<InformationPanelFormState>(parsedInfoFormState);

  useEffect(() => {
    setInfoFormState(parsedInfoFormState);
  }, [parsedInfoFormState]);

  const getOrgIdByCodeType = (id, orgType) => {
    for (let org of allOrgs) {
      if (org.code === id && org.org_type_abbrev === orgType) {
        return org.id as unknown as number;
      }
    }
    const errorMessage = new Error(`code not found for id=${id}, orgType=${orgType}!`);
    console.error(errorMessage.message);
    setMessage(errorMessage);
  }

  const buildInfoToSave = (herd, infoFormState) => {
    const origInfoObj = {
      farm_name: herd.farm_name || '',
      owner_name: herd.owner_name || '',
      species_code: herd.species_code || 0,
      num_cows: herd.num_cows || 0,
      street_address: herd.street_address || '',
      city: herd.city || '',
      state_code: herd.state_code || '',
      zip_code: herd.zip_code || '',
      person_providing_info: herd.person_providing_info || '',
      person_providing_info_datetime: herd.person_providing_info_datetime || null,
      is_active: checkBool(herd.is_active )
    };

    const formInfoObj = {
      farm_name: infoFormState.farm_name,
      owner_name: infoFormState.owner_name,
      species_code: infoFormState.species_code,
      num_cows: infoFormState.num_cows || 0,
      street_address: infoFormState.street_address,
      city: infoFormState.city,
      state_code: infoFormState.state_code,
      zip_code: infoFormState.zip_code,
      person_providing_info: infoFormState.person_providing_info || '',
      person_providing_info_datetime: infoFormState.person_providing_info_datetime || null,
      person_providing_info_from_producer: infoFormState.person_providing_info_from_producer || '',
      person_providing_info_from_producer_datetime: infoFormState.person_providing_info_from_producer_datetime || null,
      is_active: checkBool(infoFormState.is_active)
    };

    const updateOrgsObj = {
      fsp_id: getOrgIdByCodeType(infoFormState.fsp_id, 'FSP',) || 0,
      drpc_id: getOrgIdByCodeType(infoFormState.drpc_id, 'DRPC') || 0
    };

    const updateInfoObj = jsonDiff(origInfoObj, formInfoObj);
    return {info: updateInfoObj, orgs: updateOrgsObj};
  }

  const handleSaveInfo: UseMutationResult<void, AxiosError<any, any>, HerdRequestType, {previousData: unknown}> = useMutation(async (info) => {
    await herdAPI.update(herd.code as string, info);
  },
    {
      onMutate: async (info) => {
        // Cancel current queries to prevent an update clash
        await queryClient.cancelQueries({ queryKey: ["getOneHerd", `${herd.code}`] });

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(["getOneHerd", `${herd.code}`]);

        // Optimistically update to the new value
        queryClient.setQueryData(["getOneHerd", `${herd.code}`], (old) => ({...herd, ...info.info, fsp_id: infoFormState.fsp_id, drpc_id: infoFormState.drpc_id}));

        // Return a context object with the snapshot value
        return { previousData };
      },
      onSuccess: async () => {
        herdRefetch();
        setMessage({ type: 'success', message: 'Information updated.' });
        await queryClient.invalidateQueries(['getHistoryResults', String(infoFormState.code)]);
      },
      onError: (error: AxiosError, info, context) => {
        console.error(error.response?.data.message);
        queryClient.setQueryData(["getOneHerd", `${herd.code}`], context?.previousData);
        setMessage({ type: 'error', message: 'Could not update information.' })
      }
    }
  )

  return (
    <Stack direction='column' spacing={2} id={id}>
      <Stack direction={usePdfLayout ? 'column' : {xs: 'column', lg: 'row'}} spacing={usePdfLayout ? 6 : {xs: 6, lg: 4}} alignItems={usePdfLayout ? 'flex-start' : {xs: 'center', lg: 'flex-start'}}>
        <HerdInformationForm herd={herd} formState={infoFormState} setFormState={setInfoFormState} validationFn={validateFn} usePdfLayout={usePdfLayout}/>
        <HerdLocationForm herd={herd} formState={infoFormState} setFormState={setInfoFormState} validationFn={validateFn} hasValidationError={hasValidationError} usePdfLayout={usePdfLayout}/>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: '100%' }}>
        <UsernameDateTime username={herd.herd_updated_by} org={herd.herd_updated_by_organization} dateTime={herd.herd_updated_datetime} />
        {showButton && !handleSaveInfo.isLoading ?
          <Button variant='contained' color='primary' onClick={() => handleSaveInfo.mutate(buildInfoToSave(herd, infoFormState))} disabled={!(infoFormState.code && infoFormState.owner_name && !validateFn.validateCity(infoFormState.city) && !validateFn.validateZip(infoFormState.zip_code) && !validateFn.validateCount(infoFormState.num_cows))}>
            Save
          </Button>
          : <CircularProgress />}
      </Stack>
    </Stack>
  )
}
