import { useState, useContext } from 'react';
import {MessageContext} from "../../context/messageContext";
import {Box, LinearProgress, ThemeProvider, useTheme} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {HerdPanelProps} from "../../herdportalTypes";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {createTheme, responsiveFontSizes} from "@mui/material/styles";
import herdAPI from "../../apis/herdAPI";
import { historyColumns } from '../historyColumns'


const appTheme = responsiveFontSizes(createTheme({
    palette: {
        primary: { main: '#01674F' },
        secondary: { main: '#DFD7D8' }
    }
}));

const formatData = (dataArray) => {
    if (!dataArray) return [];

    interface HistoryData {
        id: null
        created_by: string
        created_datetime: string
        fields_changed: Array<string>
    }

    let resultData = [] as Array<HistoryData>;

    let formatDate = (val) => {
        const myDate = new Date(val);
        return myDate.toLocaleString();
    }

    for (let data of dataArray) {
        const changedFields = Object.keys(JSON.parse(data.delta));

        let myData = {
            id: data.id,
            created_by: data.created_by,
            created_datetime: formatDate(data.created_datetime),
            fields_changed: changedFields
        };

        if (changedFields && changedFields.length > 0) {
            resultData.push(myData);
        }
    }

    return resultData;
};

export default function HistoryPanel({ id, herd, herdRefetch, showButton, usePdfLayout }: HerdPanelProps) {
    const queryClient = useQueryClient();
    const {id: herdCode} = useParams();
    const { messageState: [, setMessage] } = useContext(MessageContext);
    const theme = useTheme();

    const getHistoryResults = useQuery(
        ['getHistoryResults', herdCode],
        () => {
            return herdAPI.getHistory(herdCode? herdCode: "");
        },
        {
            onError: (error: Error) => {
                console.error("HistoryPanel:: useQuery Error:", error);
                setMessage({ type: "error", message: error.message });
            },
        }
    );

    const handleCreateSuccess = () => {
        queryClient.invalidateQueries(["getHistoryResults", herdCode]);
    };

    return (
        <ThemeProvider theme={appTheme}>
            <Box sx={{mt: '.5rem'}}>
                <Box sx={{height: '75vh'}}>
                    <DataGridPro
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: theme.palette.secondary.main
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress
                        }}
                        columns={historyColumns}
                        rows={formatData(getHistoryResults.data) || []}
                        loading={getHistoryResults.isLoading}
                        headerHeight={35}
                        disableSelectionOnClick
                    />
                </Box>
            </Box>
        </ThemeProvider>
    )
}
