import { ChangeEvent } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {useResponsive} from "../../hooks/style";

interface AgreementLevelInterface {
  name: string;
  label: string;
  value: number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
  usePdfLayout: boolean;
}
export default function AgreementLevel({
  name,
  label,
  value,
  handleChange = () => {},
  readOnly = false,
  usePdfLayout
}: AgreementLevelInterface) {
  const {isDesktop, isPhone} = useResponsive();

  const borderStyle = "1px solid #cfcfcf";

  return (
    <Paper sx={{ p: 2, pr: isPhone && !usePdfLayout ? 0 : 2, border: borderStyle, boxShadow: 'unset' }}>
      <Stack
        direction="row"
        spacing={usePdfLayout ? 4 : {xs: 0, sm: 3, md: 4}}
        alignItems="center"
      >
        <Typography variant="body1" sx={{ marginLeft: '50px', width: isPhone && !usePdfLayout ? '40%' : '50%' }}>
          {label}
        </Typography>
        <Stack direction="row">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Code</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={name}
              value={value}
              onChange={readOnly ? () => {} : handleChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="1"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="2"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
}
