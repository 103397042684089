import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const desktopDimensions = [241.3, 279.4];  // 9.5" x 11"
const mobileDimensions = [356, 730];

async function exportToPdfImage(ids, isDesktop = false) {
  const dimensions = isDesktop ? desktopDimensions : mobileDimensions;

  const pdf = new jsPDF('p', 'mm', dimensions);
  console.log('instantiated jsPDF');

  const canvases = await Promise.all(ids.map(async id => {
    const input = document.getElementById(id);
    console.log('Got element', id);

    if (input) {
      const thing = {alias: id, canvas: await html2canvas(input, {
        onclone: (clonedDoc) => {
          const clonedElem = clonedDoc.getElementById(id);

          if (clonedElem) {
            clonedElem.style.width = `${dimensions[0]}mm`;
            clonedElem.style.height = `${dimensions[1]}mm`;
            clonedElem.style.padding = '6.4mm'; // Recommended padding for printers
          }
        }
      })};
      console.log('generated canvas for', id);
      return thing;
    }
    else return {};
  }))

  canvases.filter(e => e.alias !== undefined).forEach(({alias, canvas}, index) => {
    const imgData = canvas?.toDataURL('image/png');
    console.log('converted canvas to image/png', alias);

    if (imgData) {
      const imgProperties = pdf.getImageProperties(imgData);
      console.log('got img properties for', alias);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      console.log('got pdfWidth for', alias);
      const pdfHeight =
        (imgProperties.height * pdfWidth) / imgProperties.width;
      console.log('got pdfHeight for', alias);

      // eslint-disable-next-line new-cap
      // pdf.save(clonedElem);

      // If this is not the first page, add a page
      if (index > 0) {
        pdf.addPage(dimensions, 'p');
        console.log('Added pdf page for', alias);
      }

      pdf.addImage(imgData, 0, 0, pdfWidth, pdfHeight, alias);
      console.log('Added pdf image for', alias);
    }
  }, 0);
  console.log('Done generating PDF');

  window.open(pdf.output('bloburl'));
  console.log('Opened PDF in new window');
}

export {exportToPdfImage}