import React, { useState, useEffect, useContext, ChangeEvent, Dispatch, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Stack, Typography, TextField, InputLabel, MenuItem, FormControl, FormControlLabel, Checkbox, Select, SelectChangeEvent, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, useTheme } from '@mui/material';
import { MessageContext } from '../context/messageContext';
import orgAPI from '../apis/orgAPI';
import OrganizationForm from "./OrganizationForm";
import {IOrganization} from "./IOrganization";
import useAlert from "../hooks/alert/useAlert";

export default function ManageOrganizations() {
  const alert = useAlert();
  const { messageState } = useContext(MessageContext);
  const [, setMessage] = messageState;
  const [dataList, setDataList] = useState([]);
  const [selectedListItem, setSelectedListItem] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const defaultFormState = {
    code: '',
    name: '',
    abbrev: '',
    org_type_id: '',
    org_type_name: '',
    org_type_abbrev: '',
    description: '',
    active: false,
  };
  const [formState, setFormState] = useState(defaultFormState);
  const foundOrgs = useQuery('fetchOrgs', () => orgAPI.getAll(), {
    onError: (error) => {setMessage({type: 'error', message: error.message})}
  })

  const filterAndSort = (filterStr) => {
    const list = [...dataList];
    if (filterStr !== '') {
      setFilterList(
        list
          .filter((obj) => obj.name && obj.name.toLowerCase().indexOf(filterStr.toLowerCase()) >= 0)
          .sort((a, b) => a === b ? 0 : a < b ? -1 : 1)
      )
    } else {
      setFilterList(dataList);
    }
  }

  useEffect(() => {
    const newList = foundOrgs.data || [];
    setDataList(newList.sort((a, b) => a.name === b.name ? 0 : a.name && b.name && a.name < b.name ? -1 : 1));
  }, [foundOrgs.data, foundOrgs.isFetched]);
  useEffect(() => {filterAndSort(filterValue)}, [dataList, filterValue])

  const handleFilterChange = (event) => {
    const { target: { value } } = event;
    setFilterValue(value);
    if (value) filterAndSort(value);
    else filterAndSort(filterValue)
  }

  const createOrg = () => {
    setFormState(defaultFormState);
    setSelectedListItem(0);
    setShowEdit(true);
  };
  const handleSave = async () => {
    const foundOrg = dataList.find((obj) => obj.id === selectedListItem);
    
    const updateObj = {
      code: parseInt(formState.code),
      name: formState.name,
      abbrev: formState.abbrev,
      description: formState.description,
      active: formState.active ? true : false,
      org_type_id: formState.org_type_id
    }
    let res;

    try {
      if (foundOrg?.id) { // UPDATE
        res = await orgAPI.update(`${selectedListItem}`, updateObj);
      } else { // CREATE
        res = await orgAPI.create(updateObj);
      }

      setFormState(defaultFormState); // reset form
      foundOrgs.refetch(); // reset query
      setMessage({type: 'success', message: 'Organization updated'})
    } catch (err) {
      setMessage({type: 'error', message: err.toString()});
    }
  }

  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row'}} spacing={{ xs: 2, md: 4}} alignItems='center' sx={{px: {xs: 1, md: 0}}}>
      <Stack direction='column' spacing={1} alignSelf='start' sx={{ width: '100%' }}>
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            my="1rem"
        >
          <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => createOrg()}
          >
            Create
          </Button>
        </Stack>
        <TextField id="organization-filter-input" label="Filter by Name" variant="outlined" value={filterValue} onChange={handleFilterChange} />
        {!foundOrgs.isLoading ?
          <OrganizationTable list={filterList} selected={selectedListItem} setSelected={setSelectedListItem} setShowEdit={setShowEdit}/>
          : <Stack direction='row' justifyContent='center' alignItems='center'><CircularProgress /></Stack>
        }
      </Stack>
      <OrganizationForm organization={filterList.find((obj) => obj.id === selectedListItem)}
                        handleSave={handleSave}
                        formState={formState}
                        setFormState={setFormState}
                        defaultFormState={defaultFormState}
                        setShowEdit={setShowEdit}
                        showEdit={showEdit}
                        selected={selectedListItem}/>
    </Stack>
  )
}

function OrganizationTable({ list, selected, setSelected, setShowEdit }) {
  const { palette } = useTheme();

  const handleRowSelect = (event, orgId) => {
    if (selected !== parseInt(orgId)) {
      setShowEdit(true);
      setSelected(parseInt(orgId));
    } else {
      setSelected(0);
    }
  }

  return (
    <TableContainer component={Paper} sx={{ height: '65vh' }}>
      <Table aria-label="agreement table">
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
          <TableRow sx={{ backgroundColor: palette.secondary.main }}>
            <TableCell align="center" sx={{ px: 1 }}>Code</TableCell>
            <TableCell align="center" sx={{ px: 1 }}>Name</TableCell>
            <TableCell align="center" sx={{ px: 1 }}>Org. Type</TableCell>
            <TableCell align="center" sx={{ px: 1 }}>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((org, idx) => (
            <TableRow
              key={org.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&.Mui-selected': { backgroundColor: `${palette.primary.main}4D` },
                cursor: 'pointer'
              }}
              selected={selected === org.id}
              onClick={(event) => handleRowSelect(event, org.id)}
            >
              <TableCell align="left" sx={{ py: 0 }}>
                {`${org.code}`}
              </TableCell>
              <TableCell align="left" sx={{ py: 0 }}>
                {org.name}
              </TableCell>
              <TableCell align="center" sx={{ py: 0 }}>
                {org.org_type_abbrev}
              </TableCell>
              <TableCell align="center" sx={{ py: 0 }}>
                <Checkbox checked={org.active ? true : false} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ backgroundColor: palette.background.paper, position: 'sticky', bottom: -1, zIndex: 100, width: '100%' }}>
          <TableRow>
            <TableCell align='right'>
              Results:
            </TableCell>
            <TableCell align='left'>
              {list.length}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}


