import {ChangeEvent, Dispatch, useEffect, useState} from 'react';
import { Alert, Stack, TextField, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';

import { InformationPanelFormState } from './InformationPanel';
import stateOptions from '../../lib/US_STATES'
import { HerdViewObject, ValidationFn } from '../../herdportalTypes';
interface HerdLocFormProps {
  herd: HerdViewObject;
  formState: InformationPanelFormState;
  setFormState: Dispatch<InformationPanelFormState>;
  validationFn: ValidationFn;
  hasValidationError: Function;
  usePdfLayout?: boolean;
}
export default function HerdLocation({ herd, formState, setFormState, validationFn, hasValidationError, usePdfLayout = false }: HerdLocFormProps) {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const renderAlerts = () => {
    return errorMessages.map((msg, idx) => (
      <Alert key={idx} severity='error'>{msg}</Alert>
    ));
  };

  const addError = (errorMessages, errorMessage) => {
    if (errorMessage) errorMessages.push(errorMessage);
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = event;
    const parseList = ['code'];

    const errMessages = [];
    switch (name) {
      case 'city':
        addError(errMessages, validationFn.validateCity(value));
        addError(errMessages, validationFn.validateZip(formState.zip_code));
        setErrorMessages(errMessages);
        break;
      case 'zip_code':
        addError(errMessages, validationFn.validateZip(value));
        addError(errMessages, validationFn.validateCity(formState.city));
        setErrorMessages(errMessages);
        break;
    }
    setFormState({ ...formState, [name]: parseList.includes(name) && value !== '' ? parseInt(value) : value });
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { target: { name, value } } = event;
    setFormState({ ...formState, [name]: value });
  }

  return (
      <Stack direction="column" spacing={2} sx={{ width: '100%', maxWidth: 'sm' }}>
        {renderAlerts()}
        <Stack direction={usePdfLayout ? 'column' : {xs: 'column', sm: 'row'}} spacing={2}>
          <TextField
            id="herd-streetAddress-input"
            name="street_address"
            label="Street Address"
            value={formState.street_address}
            placeholder='1234 Old Farm Rd.'
            onChange={handleInputChange}
            sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '60%'}}}
          />
          <TextField
            id="herd-city-input"
            name="city"
            label="City"
            value={formState.city}
            placeholder='Pleasant View'
            onChange={handleInputChange}
            error={validationFn.validateCity(formState.city) ? true : false}
            sx={{width: usePdfLayout ? '100%' : {xs: '100%', sm: '40%'}}}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl sx={{ width: 100 }}>
            <InputLabel id="herd-state-select-label">State</InputLabel>
            <Select
              labelId="herd-state-select-label"
              id="herd-state-select"
              name="state_code"
              label="State"
              value={formState.state_code}
              onChange={handleSelectChange}
            >
              <MenuItem value="">No Selection</MenuItem>
              {stateOptions?.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            required
            id="herd-zipCode-input"
            name="zip_code"
            label="Zip Code"
            error={validationFn.validateZip(formState.zip_code) ? true : false}
            value={formState.zip_code}
            placeholder='85164'
            onChange={handleInputChange}
            sx={{ width: 150 }}
          />
        </Stack>
      </Stack>
  )
}