import { useState, useContext, useEffect } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment, Link, LinearProgress, TextField, useTheme } from '@mui/material';
import { Search } from "@mui/icons-material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useQuery, QueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../context/SearchContext';
import { MessageContext } from '../context/messageContext';
import CreateHerdDialog from './herd/CreateHerdDialog.tsx';
import herdAPI from '../apis/herdAPI';
import { columns } from './columns';
import { mockData } from './mockData';
import {isFSPId, isNumber, isStateCode} from '../util/index';
import {setState} from "@aws-amplify/auth/lib/OAuth/oauthStorage";

const useMockData = false;

export default function Herds() {
  const queryClient = new QueryClient();
  /**
   * HOOKS
   */
  const theme = useTheme();
  const navigate = useNavigate();
  const { searchState } = useContext(SearchContext);
  const { messageState: [, setMessage] } = useContext(MessageContext);

  /**
   * STATE
   */
  const [searchValue, setSearchValue] = searchState;
  const [searchValueLocal, setSearchValueLocal] = useState(searchValue);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isAssigned, setIsAssigned] = useState(true);
  const [sqlSearchResults, setSqlSearchResults] = useState([]);

  /**
   * SETUP
   */
  const improvedColumns = columns;
  improvedColumns.find(col => col.field === 'code').renderCell = (params) => {
    const herdCode = params.value.toString();
    const tokens = herdCode.split('|');
    if (tokens.length === 1) {
      return (
        <Link component='button' onClick={() => handleHerdClick(herdCode)}>
          {`${herdCode.slice(0, 2)}-${herdCode.slice(2, 4)}-${herdCode.slice(4, 8)}`}
        </Link>
      );
    } else {
      return (
        <div>{`${herdCode.slice(0, 2)}-${herdCode.slice(2, 4)}-${herdCode.slice(4, 8)}`}</div>
    )
    }
  };

  /**
   * QUERIES
   */
  const getHerdSearchResults = useQuery(
    ['getHerdSearchResults', searchValueLocal],
    () => {
      if (useMockData) return mockData;
      else {
        // valid search
        // 3 characters or more
        // 2 character state code
        // 2 character number: FSP search
        if (searchValue.length > 2 || isStateCode(searchValue) || isFSPId(searchValue)) {
          const searchResults = herdAPI.getSearch(searchValueLocal);
          return searchResults;
        }
      }
    },
    {
      onError: (error) => setMessage({ type: 'error', message: error.message }),
      enabled: searchValueLocal !== null
    }
  );

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (getHerdSearchResults.data && getHerdSearchResults.data[0]
      && getHerdSearchResults.data[0]['owner_name'].includes('is assigned')) {
      setIsAssigned(false);
    } else {
      setIsAssigned(true);
    }
  },[getHerdSearchResults]);

  /**
   * HANDLERS
   */
  const handleHerdClick = (herdCode) => {
    navigate(`/herds/${herdCode}`);
  };

  const handleCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const searchVisibility = () => {
    let val = 'hidden';
    if (searchValue.length >= 3) {
      val = 'visible';
    }
    return { visibility: val }
  }

  const handleCreateSuccess = () => {
    queryClient.invalidateQueries(["getHeardSearchResults", searchValueLocal]);
    setCreateDialogOpen(false);
  };

  /**
   * HELPERS
   */
  const addIdToRows = (rows) => {
    if (!rows) {
      return null;
    }
    let resultRows = [];
    for (let r of rows){
      let row = JSON.parse(JSON.stringify(r));
      row.id = row.code;
      if (row.notAssociated) {
        row.code = `${row.code}|NA`;
      }
      resultRows.push(row);
    }
    return resultRows;
  };

  /**
   * COMPONENT
   */
  return (
    <Box sx={{ mt: '.5rem', px: {xs: 1, md: 0} }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            id='search-field'
            fullWidth
            label='Search'
            value={searchValue}
            helperText={searchValue.length < 3 && `Search must include at least 3 characters.`}
            autoFocus
            sx={{ marginBottom: '2rem' }}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setSearchValueLocal(searchValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={searchVisibility()}
                >
                  <IconButton
                    onClick={() => {
                      setSearchValueLocal(searchValue);
                    }}
                  >
                    <Search fontSize="large" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Button sx={{ mt: {xs: 0, sm: 1}, px: {xs: 1} }} variant='contained' color='primary' onClick={handleCreateClick}>New Herd</Button>
        </Grid>
      </Grid>

      {isAssigned
        ? <Box sx={{ height: '75vh' }}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.secondary.main
            }
          }}
          components={{
            LoadingOverlay: LinearProgress
          }}
          columns={improvedColumns}
          rows={addIdToRows(getHerdSearchResults.data) || []}
          loading={getHerdSearchResults.isLoading}
          headerHeight={35}
          disableSelectionOnClick
        />
      </Box>
        : <Box sx={{ height: '75vh' }}>
          <DataGridPro
            columns= {[{
              field: 'code',
              headerName: 'Code',
              headerClassName: 'header',
              minWidth: 100
            },
            {
              field: 'owner_name',
              headerName: 'Is Assigned',
              headerClassName: 'header',
              minWidth: 1200
            }]}
            rows={addIdToRows(getHerdSearchResults.data) || []}
            loading={getHerdSearchResults.isLoading}
            headerHeight={35}
            disableSelectionOnClick
          />
      </Box>
      }

      <CreateHerdDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={handleCreateSuccess}
      />
    </Box>
  );
};
