import {
  ChangeEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { HerdAgreementsObject, HerdPanelProps } from "../../herdportalTypes";
import { MessageContext } from "../../context/messageContext";
import AgreementConsent from "./AgreementConsent";
import AgreementLevel from "./AgreementLevel";
import herdAPI from "../../apis/herdAPI";
import UsernameDateTime from "./UserDateTimeBox";
import { useParams } from "react-router-dom";
import {useResponsive} from "../../hooks/style";

export default function AgreementsPanel({ id, herd, herdRefetch, showButton = true, usePdfLayout = false }: HerdPanelProps) {
  const { id: herdCode } = useParams();
  const { messageState } = useContext(MessageContext);
  const queryClient = useQueryClient();

  const [, setMessage] = messageState;

  const [dataConsent, setDataConsent] = useState(herd.dataConsent ? 1 : 0);
  const [piiConsent, setPiiConsent] = useState(herd.piiConsent ? 1 : 0);
  const [isReadOnly, setIsReadOnly] = useState(herd.dataConsent ? false: true);
  // todo: future feature, waiting for Jay to give the OK
  // const [breedConsent, setBreedConsent] = useState(herd.piiConsent ? 1: 0);

  const [healthLevel, setHealthLevel] = useState<number>(
    (herd.healthLevel as number) || 0
  );
  const [productionLevel, setProductionLevel] = useState<number>(
    (herd.productionLevel as number) || 0
  );
  const [reproductionLevel, setReproductionLevel] = useState<number>(
    (herd.reproductionLevel as number) || 0
  );
  const [managementLevel, setManagementLevel] = useState<number>(
    (herd.managementLevel as number) || 0
  );
  const [sensorLevel, setSensorLevel] = useState<number>(
    (herd.sensorLevel as number) || 0
  );
  const [nutritionLevel, setNutritionLevel] = useState<number>(
    (herd.nutritionLevel as number) || 0
  );
  const [otherLevel, setOtherLevel] = useState<number>(
    (herd.otherLevel as number) || 0
  );

  useEffect(() => {
    setDataConsent(herd.dataConsent ? 1 : 0);
    setPiiConsent(herd.piiConsent ? 1 : 0);
    setHealthLevel(herd.healthLevel || 0);
    setProductionLevel(herd.productionLevel || 0);
    setReproductionLevel(herd.reproductionLevel || 0);
    setManagementLevel(herd.managementLevel || 0);
    setSensorLevel(herd.sensorLevel || 0);
    setNutritionLevel(herd.nutritionLevel || 0);
    setOtherLevel(herd.otherLevel || 0);
  }, [herd]);

  const consentArray = [
    {
      label: "Personal Identifying Information",
      name: "piiConsent",
      consent: piiConsent,
    },
    {
      label: 'Data Disclosure & Publication',
      name: "dataConsent",
      consent: dataConsent,
    },
    // todo: future feature, waiting for Jay to give the OK
    // {label: 'Breed Association', name: 'breedConsent', consent: breedConsent, isVisible: true}
  ];

  const levelArray = [
    { label: "Health", name: "healthLevel", level: healthLevel },
    { label: "Production", name: "productionLevel", level: productionLevel },
    {
      label: "Reproduction",
      name: "reproductionLevel",
      level: reproductionLevel,
    },
    { label: "Management", name: "managementLevel", level: managementLevel },
    { label: "Sensor", name: "sensorLevel", level: sensorLevel },
    { label: "Nutrition", name: "nutritionLevel", level: nutritionLevel },
    { label: "Other", name: "otherLevel", level: otherLevel },
  ];

  const levelChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    const valInt = parseInt(value);

    switch (name) {
      case "healthLevel":
        setHealthLevel(valInt);
        break;
      case "productionLevel":
        setProductionLevel(valInt);
        break;
      case "reproductionLevel":
        setReproductionLevel(valInt);
        break;
      case "managementLevel":
        setManagementLevel(valInt);
        break;
      case "sensorLevel":
        setSensorLevel(valInt);
        break;
      case "nutritionLevel":
        setNutritionLevel(valInt);
        break;
      case "otherLevel":
        setOtherLevel(valInt)
        break;
      default:
        console.info(`level=${name} is not valid with value=${valInt}`);
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    const valInt = parseInt(value);
    const YES = 1;

    switch (name) {
      case "dataConsent":
        setDataConsent(valInt as number);
        const val = (valInt as number) === 0 ? 0 : 3;

        if (valInt === YES) {  // data consent is YES
          setIsReadOnly(false);
        } else {
          setIsReadOnly(true);
        }

        setHealthLevel(val);
        setProductionLevel(val);
        setReproductionLevel(val);
        setManagementLevel(val);
        setSensorLevel(val);
        setNutritionLevel(val);
        setOtherLevel(val);
        break;
      case "piiConsent":
        setPiiConsent(valInt as number);
        break;
      case "healthLevel":
        setHealthLevel(valInt as number);
        break;
      case "productionLevel":
        setProductionLevel(valInt as number);
        break;
      case "reproductionLevel":
        setReproductionLevel(valInt as number);
        break;
      case "managementLevel":
        setManagementLevel(valInt as number);
        break;
      case "sensorLevel":
        setSensorLevel(valInt as number);
        break;
      case "nutritionLevel":
        setNutritionLevel(valInt as number);
        break;
      case "otherLevel":
        setOtherLevel(valInt as number);
        break;
      default:
        throw new Error(
          `Error in handleChange: name=${name} value=${value}, valInt=${valInt}`
        );
    }
  };

  const handleSaveAgreements = useMutation(
    () => {
      const updateObj = {} as HerdAgreementsObject;

      for (let rec of levelArray) {
        updateObj[rec.name] = rec.level as unknown as number;
      }

      for (let rec of consentArray) {
        updateObj[rec.name] = rec.consent as unknown as number;
      }

      return herdAPI.update(herd.code as string, {
        info: {},
        agreement: updateObj,
      });
    },
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["getOneHerd", herdCode]);

        // Snapshot the previous value
        const previousHerdData = queryClient.getQueryData<object>([
          "getOneHerd",
          herdCode,
        ]);

        // Optimistically update to the new values
        queryClient.setQueryData(["getOneHerd", herdCode], {
          ...previousHerdData,
          dataConsent,
          piiConsent,
          healthLevel,
          productionLevel,
          reproductionLevel,
          managementLevel,
          sensorLevel,
          nutritionLevel,
          otherLevel,
        });

        // Return a context object with the snapshotted value
        return { previousHerdData };
      },
      onSettled: herdRefetch, // Always refetch after error or success
      onSuccess: async () => {
        setMessage({
          type: "success",
          message: "Agreement preferences updated.",
        });
        await queryClient.invalidateQueries(['getHistoryResults', herdCode]);
      },
      onError: (error: AxiosError, newHerdData, context) => {
        console.error(error.response?.data.message);
        setMessage({ type: "error", message: "Could not update agreements." });

        // Reset the query data to the previous herd data
        queryClient.setQueryData(
          ["getOneHerd", herdCode],
          context?.previousHerdData || {}
        );
      },
    }
  );

  return (
    <Stack id={id} direction="column" spacing={usePdfLayout ? 2 : 4}>
      <Typography variant="h6" textAlign="center">
        Data Access and Use Agreement
      </Typography>
      <AgreementsTable rows={accessUseAgreementLevels} usePdfLayout={usePdfLayout} />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={usePdfLayout ? 2 : {xs: 1, sm: 2, md: 4}}
      >
        {consentArray.map((c) => (
          <AgreementConsent
            key={c.name}
            name={c.name}
            label={c.label}
            value={c.consent}
            handleChange={handleChange}
            usePdfLayout={usePdfLayout}
          />
        ))}
        {levelArray.map((ag) => (
          // @ts-ignore
          <AgreementLevel
            key={ag.name}
            name={ag.name}
            label={ag.label}
            value={ag.level}
            handleChange={levelChange}
            readOnly={isReadOnly}
            usePdfLayout={usePdfLayout}
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        {/* TODO: make these buttons do something! */}
        <UsernameDateTime
          username={herd.agreement_updated_by}
          org={herd.agreement_updated_by_organization}
          dateTime={herd.agreement_updated_datetime}
        />
        {showButton && !handleSaveAgreements.isLoading ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveAgreements.mutate()}
          >
            Save
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Stack>
    </Stack>
  );
}

/**
 * CHILDREN COMPONENTS
 */
interface AgreementsTableProps {
  rows: accessUseAgreementLevelObj[];
  usePdfLayout: boolean;
}

function AgreementsTable({ rows, usePdfLayout }: AgreementsTableProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = usePdfLayout ? false : useMediaQuery(breakpoints.down("sm"));

  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const handleDialogOpen = (event) => {
    const {
      target: { id },
    } = event;
    setOpenDialog(id);
  };
  const handleDialogClose = () => {
    setOpenDialog(null);
  };

  return (
    <TableContainer component={Paper} sx={{boxShadow: 'unset'}}>
      <Table aria-label="agreement table">
        <TableHead>
          <TableRow sx={{ backgroundColor: palette.secondary.main }}>
            <TableCell sx={{ px: 1 }}></TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              CODE
            </TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              PERMITTED DISCLOSURE
            </TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              PERMITTED USE
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={row.level}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="center" sx={{ p: 0 }}></TableCell>
              <TableCell align="center" sx={{ p: 0 }}>
                {row.level}
              </TableCell>
              <TableCell
                align={isMobile ? "center" : "left"}
                sx={{ verticalAlign: "text-top", px: 1 }}
              >
                {isMobile ? (
                  <TextDialog
                    open={openDialog === `View Disclosure-${row.level}`}
                    handleClickOpen={handleDialogOpen}
                    handleClose={handleDialogClose}
                    label="View Disclosure"
                    level={row.level}
                    fullText={row.disclosure}
                  />
                ) : (
                  row.disclosure
                )}
              </TableCell>
              <TableCell align="left" sx={{ verticalAlign: "text-top", px: 1 }}>
                {isMobile ? (
                  <TextDialog
                    open={openDialog === `View Use-${row.level}`}
                    handleClickOpen={handleDialogOpen}
                    handleClose={handleDialogClose}
                    label="View Use"
                    level={row.level}
                    fullText={row.use}
                  />
                ) : (
                  row.use
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align="left" colSpan={3} sx={{ verticalAlign: "text-top", px:1, paddingLeft: "38px" }}>
              <a href="https://dhia.org/wp-content/uploads/2021/08/Uniform-Operating-Procedures-Effective-01262021.pdf" target="_blank" rel="noreferrer">
                Uniform Operating Procedures Effective 01-26-2021
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface TextDialogProps {
  open: boolean;
  handleClickOpen: MouseEventHandler<HTMLSpanElement>;
  handleClose: () => void;
  label: string;
  level?: string | number;
  fullText: string;
}

function TextDialog({
  open,
  handleClickOpen,
  handleClose,
  label,
  level,
  fullText,
}: TextDialogProps) {
  return (
    <>
      <Link id={`${label}-${level}`} onClick={handleClickOpen}>
        {label}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {label} {level}
        </DialogTitle>
        <Typography variant="body1" sx={{ px: 3, pb: 3 }}>
          {fullText}
        </Typography>
      </Dialog>
    </>
  );
}

/**
 * CONSTANTS
 */
interface accessUseAgreementLevelObj {
  level: number;
  disclosure: string;
  use: string;
}

const accessUseAgreementLevels = [
  {
    level: 1,
    disclosure:
      "Data remains within the DHI system only and does not go beyond DRPC",
    use: "Data can be used by Field Service Affiliates, Laboratories, and DRPCs to create new management tools and benchmarks.",
  },
  {
    level: 2,
    disclosure:
      "Data flows from the DRPC to CDCB and AGIL, but does not go beyond CDCB or AGIL",
    use: "Research, management benchmarks, and the calculation and distribution of genetic and genomic evaluations",
  },
  {
    level: 3,
    disclosure:
      "Data flows from the DRPC to CDCB and AGIL, and may then flow to any authorized recipient",
    use: "The specific purpose identified in the third-party license agreement entered between National DHIA and the authorized recipient",
  },
] as accessUseAgreementLevelObj[];
