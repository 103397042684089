import { useState, useEffect } from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface BreadcrumbNavProps {
  tab?: string;
}

export default function BreadcrumbNav({ tab }: BreadcrumbNavProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [pathSections, setPathSections] = useState<string[]>(['herds']);
  const [pathHrefs, setPathHrefs] = useState<string[]>(['/herds']);


  const toFirstCapitolString = (lowercase: string[]): string[] => {
    
    const output = lowercase.map((word) => {
      const firstCapitol = word[0].toUpperCase();
      const theRest = word.slice(1);
      return `${firstCapitol}${theRest}`
    })
    
    return output;
  }

  useEffect(() => {
    const splitPathArr = pathname.split('/').filter((element) => element.length >= 1);
    if (tab) splitPathArr.push(tab);
    const formattedPathArr = toFirstCapitolString(splitPathArr);    
    setPathSections(formattedPathArr);
    
    const newPathArr = [] as string[];
    /**
     * CONCATINATING SECTIONS TO BUILD paths
     */
    splitPathArr.forEach((element, idx) => {
      if (idx >= 1) {
        newPathArr.push(`${newPathArr[idx - 1]}/${element}`);
      } else {
        newPathArr.push(`/${element}`);
      }
    })

    setPathHrefs(newPathArr);
  }, [pathname, tab])

  const formatPathSection = (str) => {
    let myStr = str;
    if (myStr.length === 8) {
      myStr = myStr.substring(0, 2) + '-' + myStr.substring(2,4) + '-' + myStr.substring(4,8);
    }
    return myStr;
  }
  
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{pb: 1, pl: {xs: 1}}}>
      {pathSections.map((pathSection, idx) => (
        <Link key={pathSection} underline="none" onClick={() => navigate(pathSection === tab ? pathname : `${pathHrefs[idx]}`)} sx={{ cursor: 'pointer' }}>
          {formatPathSection(pathSection)}
        </Link>
      ))}
    </Breadcrumbs>
  )
}