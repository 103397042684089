import {ChangeEvent, useContext, useEffect, useState} from "react";
import {Button, Link, Paper, Stack, TextField, Typography,} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import useSession from "../hooks/user/useSession";
import {MessageContext} from "../context/messageContext";
import {PulseLoader} from "react-spinners";
import {useResponsive} from "../hooks/style";

const testUsername = "TestUser";

export default function Login() {
  const navigate = useNavigate();
  const { login } = useSession();
  const {
    messageState: [, setMessage],
  } = useContext(MessageContext);
  const {isDesktop, isPhone} = useResponsive();

  const emailRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setValidationCode] = useState("");
  const [inputsValid, setInputsValid] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);

  const validateInputs = () => {
    const validEmail = emailRegex.test(email) || email === testUsername;
    const validPassword = !!password.length;
    const inputsValid = validEmail && validPassword;
    setInputsValid(inputsValid);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "validationCode":
        setValidationCode(value);
        break;
      default:
        setMessage({ type: "error", message: `Cannot handle change event.` });
        console.error(`Cannot handle change event.`);
        break;
    }
  };

  const handleLogin = () => {
    setLoggingIn(true);
    login(
      email,
      password,
      () => navigate("/"),
      (err) => {
        setLoggingIn(false);
        setMessage({ type: "error", message: err?.message || 'An unknown error occurred attempting to log in.' })
      }
    );
  };

  useEffect(validateInputs, [email, password]);

  return (
    <Paper sx={{ px: isPhone ? 2 : 0, py: 6, mx: isPhone ? 2 : "auto", maxWidth: "sm" }}>
      <Stack
        direction="column"
        spacing={4}
        alignItems="center"
        sx={{ mx: "auto", maxWidth: 300 }}
      >
        <Typography variant="h4">Login</Typography>

        <TextField
          variant="outlined"
          fullWidth
          error={!(emailRegex.test(email) || email === testUsername) && !!email}
          type="email"
          label="Email"
          name="email"
          value={email}
          onChange={handleTextChange}
        />
        <TextField
          variant="outlined"
          fullWidth
          type="password"
          label="Password"
          name="password"
          value={password}
          onChange={handleTextChange}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 2, width: "100%" }}
        >
          <Stack spacing={2}>
            <Link component={RouterLink} to="/forgot-password">
              Forgot password?
            </Link>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            disabled={!inputsValid || isLoggingIn}
            onClick={handleLogin}
          >
            { isLoggingIn ? (
              <PulseLoader color='white' />
            ) : 'Login'}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
